var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"set-curriculum-wrap"},[_c('div',{staticClass:"operation-bar clearfix",staticStyle:{"margin-top":"10px","margin-bottom":"10px"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.add}},[_vm._v("添加活动管理员")])],1),(!!_vm.tableData.content)?_c('div',{staticClass:"table-box table-hover"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData.content}},[_c('el-table-column',{attrs:{"label":"教师姓名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!row.isEdit)?_c('span',[_vm._v(_vm._s(row.tuser.name))]):_c('el-select',{attrs:{"filterable":"","placeholder":"请选择","multiple":""},model:{value:(row.teacherGuid),callback:function ($$v) {_vm.$set(row, "teacherGuid", $$v)},expression:"row.teacherGuid"}},_vm._l((_vm.teacherList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)]}}],null,false,2433646809)}),_c('el-table-column',{attrs:{"label":"所属学校"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!row.isEdit)?_c('span',[_vm._v(_vm._s(row.tuser.schoolName))]):_vm._e()]}}],null,false,1492789376)}),_c('el-table-column',{attrs:{"label":"操作","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(!row.isEdit)?_c('div',{staticClass:"btn-box"},[_c('el-button',{staticClass:"btn-text-del",attrs:{"type":"text"},on:{"click":function($event){return _vm.del(row.tuser.id)}}},[_vm._v("删除")])],1):_c('div',{staticClass:"btn-box"},[_c('el-button',{staticClass:"btn-text-primary",attrs:{"type":"text"},on:{"click":function($event){return _vm.finish(row, $index)}}},[_vm._v("完成")]),_c('el-button',{staticClass:"btn-text-primary",attrs:{"type":"text"},on:{"click":function($event){return _vm.cancel(row, $index)}}},[_vm._v("取消")])],1)]}}],null,false,3680218992)})],1),(_vm.pagination.totalPages > 0)?_c('z-pagination',{attrs:{"page":_vm.pagination.page,"limit":_vm.pagination.size,"total":_vm.pagination.totalPages},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.pagination, "size", $event)},"pagination":_vm.init}}):_vm._e()],1):_c('v-nodata',{attrs:{"loading":_vm.isTure}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }