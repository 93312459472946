<template>
    <!--config-index-->
    <section class="set-curriculum-wrap">
        <!--操作栏 start-->
        <div class="operation-bar clearfix" style="margin-top: 10px;margin-bottom: 10px;">
            <el-button type="primary" @click="add">添加活动管理员</el-button>
        </div>
        <!--操作栏 end-->
        <!--table start-->
        <div class="table-box table-hover" v-if="!!tableData.content">
            <el-table :data="tableData.content" style="width: 100%">
                <el-table-column label="教师姓名">
                    <template slot-scope="{ row }">
                        <span v-if="!row.isEdit">{{row.tuser.name}}</span>
                        <el-select v-else filterable v-model="row.teacherGuid" placeholder="请选择" multiple>
                            <el-option v-for="item in teacherList" :key="item.id" :label="item.name"
                                       :value="item.id"></el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="所属学校">
                    <template slot-scope="{ row }">
                        <span v-if="!row.isEdit">{{row.tuser.schoolName}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="120">
                    <template slot-scope="{ row, $index }">
                        <div class="btn-box" v-if="!row.isEdit">
                            <el-button class="btn-text-del" type="text" @click="del(row.tuser.id)">删除</el-button>
                        </div>
                        <div class="btn-box" v-else>
                            <el-button class="btn-text-primary" type="text" @click="finish(row, $index)">完成</el-button>
                            <el-button class="btn-text-primary" type="text" @click="cancel(row, $index)">取消</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <z-pagination
                    v-if="pagination.totalPages > 0"
                    :page.sync="pagination.page"
                    :limit.sync="pagination.size"
                    :total="pagination.totalPages"
                    @pagination="init"
            ></z-pagination>
        </div>
        <!--table end-->

        <v-nodata v-else :loading="isTure"/>

    </section>
</template>

<script>
    export default {
        data() {
            return {
                isView: false,
                isTure: false,
                tableData: [],
                teacherList: [],
                pagination: {
                    page: 0, //当前页
                    size: 10, //分页条数
                    totalPages: 0 //总条数
                },
            }
        },
        mounted: function () {
            this.$nextTick(function () {
                this.allTeachers();
                this.init();
            })
        },

        methods: {
            cancel(row, index) {
                this.isView = false;
                if (!!row.guid) {
                    row.isEdit = false;
                    this.init();
                } else {
                    this.tableData.content.splice(index, 1);
                }
            },
            finish(row, index) {
                let temp = this;
                let a = temp.tableData.content[index].teacherGuid.join();
                this.$post('/saveActivityTeacherInfo', {"userids": a}).then(function (m) {
                    temp.isView = false;
                    // row.isEdit = false;
                    temp.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                    temp.init();
                });
            },
            allTeachers() {
                let temp = this;
                this.$post('/queryTeacherList').then(function (m) {
                    temp.teacherList = m.content.teacherList;
                });
            },
            add() {
                if (!this.isView) {
                    this.isView = true;
                    this.tableData.content.unshift({
                        teacherGuid: [],
                        isEdit: true,
                    })
                } else {
                    this.$message({
                        type: 'error',
                        message: '只能操作一条信息数据!'
                    });
                }
            },
            del(guid) {
                var temp = this;
                this.$confirm('确定删除吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    temp.$post('/deleteActivityTeacherInfoById', {"userid": guid}).then(function (m) {
                        temp.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        temp.init();
                    });
                });
            },
            init() {
                this.isTure = true;
                var temp = this;
                //查询任课教师信息
                this.$post('/queryActivityTeacherList', {
                    "page": this.pagination.page,
                    "size": this.pagination.size
                }).then(function (m) {
                    temp.isTure = false;
                    temp.tableData = m.content.page;
                    temp.pagination.page = m.content.page.number;
                    temp.pagination.totalPages = m.content.page.totalElements;
                });
            },
        }
    }
</script>
